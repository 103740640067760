import React from 'react';
import FlexGridRowComponent, {FlexGridColumnComponent} from '../layouts/FlexGrid/FlexGridRow'
import TitleFont from '../Text/TitleFont';
import Line from '../icons/lines/Line';

import './NavBar.css'
// import { Link } from 'react-router-dom';

function NavBar({header, sticky, className}){
  const classes = ["margin-bottom-1","full-width navbar"];
  if (sticky) {
    if (sticky === "left") {
      classes.push("stick-left");
    } else if (sticky === "right") {
      classes.push("stick-right");
    } else {
      classes.push("stick-center")
    }
  } else {
    classes.push("stick-center")
  }
  
  if (className) {
    classes.push(className)
  }
  return (
    <>
      <FlexGridRowComponent className={classes.join(' ')}>
        <FlexGridColumnComponent >
          <TitleFont className="text-center color-palette-white-tx text-font-size-1" >
            {header}
          </TitleFont>
        </FlexGridColumnComponent>
        <Line/>
      </FlexGridRowComponent>
    </>
  )
}

export default NavBar