import React from 'react';
import './ImageCard.css'; // CSS file for styling
import { FlexGridItem } from '../layouts/FlexGrid/FlexGridRow';
import { TextHandwritten } from '../Text/TitleFont';
import BlankPad from '../Margins/BlankPad';


export function ImageFilled({url, alt, className}) {
  const classes = ["image-filled"];

  if (className) {
    classes.push(className);
  }

  return (
      <img src={url} alt={alt} className={classes.join(' ')} />
  )
}

export function ImageCard({content}) {
  return (
    <FlexGridItem className="image-card">
        <img src={content.url} alt={content.alt} />
      <BlankPad/>
      <TextHandwritten>{content.description}</TextHandwritten>
    </FlexGridItem>
  )
}

function ImageCardGrid({data}) {
  console.log(data);
  return (
    <FlexGridItem className="image-card-container">
      {
        data.map((item) => (
          <ImageCard content={item}/>
        ))
      }
    </FlexGridItem>
  )
}

export default ImageCardGrid;