import React from 'react';
import './OutlinedButton.css'; // CSS file for styling
import BaseComponent from '../../base/Base';

function OutlinedButton({ className, children, onClick }) {
  return (
    
    <BaseComponent className={className}>
      <button className="outlined-button" onClick={onClick}>
        {children}
      </button>
  </BaseComponent>
  );
}

export default OutlinedButton;