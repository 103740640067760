import { useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import "./Carousel.css";

export function Carousel({ images }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  console.log(images);
  // Slideshow
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  //   }, 3000);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, [images]);

  
  const goToNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPrevImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  // const handlers = useSwipeable({
  //   onSwipedLeft: () => goToNextImage(),
  //   onSwipedRight: () => goToPrevImage(),
  //   swipeDuration: 500,
  //   preventScrollOnSwipe: true,
  //   trackMouse: true
  // });

  return (
    <div className="carousel" aria-live="polite">
      <div className="slider">
        <img
          src={images[currentIndex].url}
          alt={`${currentIndex}`}
          className="carousel-image"
          />
      </div>

      <div className="controls">
        <button
          className="carousel-button prev-button"
          onClick={goToPrevImage}
          aria-label="Previous Image"
          >
          <FiChevronLeft />
        </button>
        <div className="indicator-dots" aria-label="Image Navigation">
          {images.map((_, index) => (
            <div
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            role="button"
            tabIndex={0}
            onClick={() => setCurrentIndex(index)}
            aria-label={`Image ${index + 1}`}
            aria-pressed={index === currentIndex}
            ></div>
            ))}
        </div>
        <button
          className="carousel-button next-button"
          onClick={goToNextImage}
          aria-label="Next Image"
          >
          <FiChevronRight />
        </button>
      </div>
    </div>
  );
}