import React from 'react';
import './Table.css'

function Table({data}) {
  return (
    <table className='table'>
      <tbody>
        {data.map(item => (
          <TableRow rowData={item} />
        ))}
      </tbody>
    </table>
  );

} 

function TableRow({rowData}) {
  console.log(rowData);
  return (
    <tr>
      {rowData.map((value) => (
        <TableColumn content={value} />
      ))}
    </tr>
  )
}

function TableColumn({content}) {
  return <td>{content}</td>
}
export default Table;