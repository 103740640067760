import { Carousel } from '../components/Carousel/Carousel';
import Table from '../components/Table/Table';
import { H2, TextNormal } from '../components/Text/TitleFont';
import ImageCardGrid from '../components/cards/ImageCard';
import { FlexGridItem } from '../components/layouts/FlexGrid/FlexGridRow';
import Navbar from '../components/navbar/NavBar'
import FullPageLayout from '../components/page/FullPageLayout';


function Ishani() {
  const images  = [
  {url: 'images/pexels-anubhaw-anand-3236651.webp',alt: 'Image 1'},
  { url: 'images/pexels-anubhaw-anand-3263993.webp', alt:'Image 2' },
  { url: 'images/pexels-zura-modebadze-4567833.webp', alt:'Image 2' },
  { url: 'images/pexels-farzin-yarahmadi-10699274.webp', alt:'Image 2' }
  ]

  // const biodata = [
  //   [ "Height" , "165cm" ],
  //   [  "Hair" , "long" ],
  //   [  "Genre" ,  "fashion" ],
  // ];

  const clients = [
    [ "Client 1", "Client 2", "Client 3" ]
  ]

  const gallery_content = [
    { 
        "url": "images/pexels-anubhaw-anand-3236651.webp",
        "alt": "pexels-anubhaw-anand-3236651",
        "description": "Effortlessly chic: Unveiling the essence of urban elegance."
    },
    { 
        "url": "images/pexels-zura-modebadze-4567833.webp",
        "alt": "pexels-zura-modebadze-4567833",
        "description": "Sartorial poetry: Weaving tales through couture craftsmanship."
    },
    { 
        "url": "images/pexels-anubhaw-anand-3263993.webp",
        "alt": "pexels-anubhaw-anand-3263993",
        "description": "Runway magic: Bringing dreams to life, one stride at a time."
    },
    { 
        "url": "images/pexels-farzin-yarahmadi-10699274.webp",
        "alt": "pexels-farzin-yarahmadi-10699274",
        "description": "Street style symphony: Where urban vibes meet haute couture."
    },
    { 
        "url": "images/pexels-ashim-das-10443564.webp",
        "alt": "pexels-ashim-das-10443564",
        "description": "Glamour in motion: Capturing the artistry of movement and style."
    },
    { 
        "url": "images/pexels-rdne-stock-project-7685511.webp",
        "alt": "pexels-rdne-stock-project-7685511",
        "description": "Bold hues and bolder statements: A journey through vibrant fashion."
    },
    { 
        "url": "images/pexels-anubhaw-anand-3765976.webp",
        "alt": "pexels-anubhaw-anand-3765976",
        "description": "Elegance reimagined: Redefining sophistication with every pose."
    },
    { 
        "url": "images/pexels-airam-datoon-11555718.webp",
        "alt": "pexels-airam-datoon-11555718",
        "description": "Runway reverie: Channeling confidence, grace, and panache."
    },
    { 
        "url": "images/pexels-airam-datoon-11555709.webp",
        "alt": "pexels-airam-datoon-11555709",
        "description": "Unveiling individuality: Celebrating fashion as an extension of self."
    },
    { 
        "url": "images/pexels-airam-datoon-11555739.webp",
        "alt": "pexels-airam-datoon-11555739",
        "description": "Whispers of lace and silk: A rendezvous with ethereal fashion."
    },
    { 
        "url": "images/pexels-iván-cauich-17838188.webp",
        "alt": "pexels-iván-cauich-17838188",
        "description": "Fashion fusion: Where cultural inspirations meet contemporary trends."
    }
]

  return (
    <>
      <Navbar header="Ishani" sticky="left"/>
      {/* <DynamicImageGrid images={images}/> */}
      <Carousel images={images}/>
      <FullPageLayout className="text-normal color-palette-white-tx">
        <H2>BIO</H2>
        <FlexGridItem className="width-70 flex-grid-row flex-wrap flex-center">
          <TextNormal>
          <p>Step into the enchanting world of Ishani, where fashion is more 
          than just clothing—it's a statement of identity. With an innate flair for turning fabrics into art and trends into expressions, 
          Ishani has swiftly risen to redefine modern modeling. </p>

          <p>&nbsp; &nbsp; &nbsp;Hailing from [Hometown], 
          Ishani's journey is a tale of passion and perseverance. Her portfolio reads like a captivating storybook, with each photoshoot and runway show capturing a chapter of her style evolution. 
          Beyond the glamorous exterior, Ishani stands as a role model, using her influence to champion body positivity and authenticity 
          in an industry that often craves conformity. </p>

          <p>&nbsp; &nbsp; &nbsp;Join Ishani on this exciting voyage, where fashion meets individuality, and elegance knows no bounds.</p>
          </TextNormal>
          {/* <FlexGridItem className="bio-table-width">
            <Table data={biodata} />
          </FlexGridItem> */}
          <FlexGridItem className="flex-wrap flex-center width-90" >
            <Table data={clients}/>
          </FlexGridItem>
        </FlexGridItem>
      </FullPageLayout>
      <FullPageLayout className="text-normal color-palette-white-tx" >
        <H2>Experience</H2>
        
        <ImageCardGrid data={gallery_content}/>
      </FullPageLayout>
      <FullPageLayout className="color-palette-white-tx width-90" >
        Disclaimer: This portfolio website is a fictional one and doesn't consitute for any real person. Any resembelance is purely coincidental. Bio content generated using LLM Models. Images from Pexels.com
      </FullPageLayout>

    </>
  );
}

export default Ishani;
