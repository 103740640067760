import React from 'react';
import './ImageGrid.css'; // CSS file for styling
import BaseComponent from '../../base/Base';
import { FlexGridItem } from '../FlexGrid/FlexGridRow';


export function ImageItem({url, alt, className}) {
  return (
    <img src={url} alt={alt} className='image'/>
  )
}

export function ImageItemSquare({src, alt, className}) {
  return (
    <div className='square-image-container'>
      <img src={src} alt={alt} className='square-image'/>
    </div>
  )
}

export function ImageGridSquare({images, className}) {
  console.log(images);
  return (
    <FlexGridItem className="flex-wrap flex-center">
      {images.map(item => (
          <ImageItemSquare src={item.src} alt={item.alt} />
        ))
      }
    </FlexGridItem>
  )
}

function DynamicImageGrid({ images , className}) {
  return (
    <BaseComponent className={className}>
      <div className='image-grid-container'>
          {images.map((image, index) => (
            <div key={index} className="image-container">
              {/* <img src={image.url} alt={image.alt} style={{}}/> */}
              <ImageItem url={image.url} alt={image.alt}/>
            </div>
          ))}
      </div>
      {/* <FlexGridColumnComponent>
        <FlexGridItem className="flex-center image-grid-container">
        </FlexGridItem>
      </FlexGridColumnComponent>
      <div className="dynamic-image-grid">
      </div> */}
    </BaseComponent>

  );
}

export default DynamicImageGrid;