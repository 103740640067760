import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route, Outlet, Link } from "react-router-dom";
import Ishani from './pages/Ishani';
import FullPageLayout from './components/page/FullPageLayout';
import Home from './pages/Home';
import Jane from './pages/Jane';

function LayoutBase() {
  return (
    <>
      <Outlet/>
    </>
  )
}

function BlankNotFoundPage(){
  return (
    
    <FullPageLayout>
      Page not found. Navigate to <Link to="/">Home</Link>
    </FullPageLayout>
  )
}

function App() {
 

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutBase />}>
          <Route index element={<Home />} />
          <Route path="/ishani" element={< Ishani />  } />
          
          <Route path="/jane" element={< Jane />  } />
          <Route path="*" element={< BlankNotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
