import React from 'react';
import FullPageLayout from '../components/page/FullPageLayout';
import NavBar from '../components/navbar/NavBar';
import { Link } from 'react-router-dom';
import OutlinedButton from '../components/buttons/outlinedButtons/outlinedButtons';
import { TextNormal } from '../components/Text/TitleFont';

function Home() {
  return (
    <>
    <NavBar header="Kopoutech Portfolio" sticky="center"></NavBar>
    <FullPageLayout>
          <Link to="/ishani" className="color-palette-white-tx no-link-decor">
            <OutlinedButton > 
                <TextNormal className="color-palette-white-tx ">
                Ishani - A Fashion portfolio
                </TextNormal>
            </OutlinedButton>
          </Link>
          <Link to="/jane" className="color-palette-white-tx no-link-decor">
            <OutlinedButton > 
                <TextNormal className="color-palette-white-tx ">
                Jane - An Influencer
                </TextNormal>
            </OutlinedButton>
          </Link>

    </FullPageLayout>
    </>
  )
}

export default Home;