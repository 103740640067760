import React from 'react';
import TitleFont, { H2, TextNormal } from '../components/Text/TitleFont';
import { ImageFilled} from '../components/cards/ImageCard';
import Line from '../components/icons/lines/Line';
import { FlexGridItem } from '../components/layouts/FlexGrid/FlexGridRow';
import FullPageLayout from '../components/page/FullPageLayout';

import './Jane.css'
import { ImageGridSquare } from '../components/layouts/ImageGrid/ImageGrid';
export default function Jane() {
  const imageList = [
    { src: 'images/anna/pexels-anna-kollor-14330842.webp', alt: 'Image 1' },
    { src: 'images/anna/pexels-anna-kollor-14330843.webp', alt: 'Image 2' },
    { src: 'images/anna/pexels-anna-kollor-14330838.webp', alt: 'Image 3' },
    { src: 'images/anna/pexels-anna-kollor-14330831.webp', alt: 'Image 4' },
    { src: 'images/anna/pexels-anna-kollor-14330806.webp', alt: 'Image 6' },
    { src: 'images/anna/pexels-anna-kollor-14330798.webp', alt: 'Image 7' },
    { src: 'images/anna/pexels-anna-kollor-14330784.webp', alt: 'Image 8' },
    { src: 'images/anna/pexels-anna-kollor-14330782.webp', alt: 'Image 9' },
    { src: 'images/anna/pexels-anna-kollor-14330822.webp', alt: 'Image 11' },
    { src: 'images/anna/pexels-anna-kollor-14330818.webp', alt: 'Image 12' },
    { src: 'images/anna/pexels-anna-kollor-14330794.webp', alt: 'Image 13' },
    { src: 'images/anna/pexels-anna-kollor-14330779.webp', alt: 'Image 14' }
  ];

  console.log(imageList)
  return (
    <>
      {/* <Navbar header="Jane" sticky="center"/> */}
      <FullPageLayout className="jane-background-image">
        <FlexGridItem className="jane-content-cover jane-text-position flex-grid-row">
          <TitleFont className="text-center color-palette-white-tx text-font-size-1" >
            Jane Smith
          </TitleFont>
          <Line className="width-70"/>
        </FlexGridItem>
      </FullPageLayout >
      <FlexGridItem className="jane-full-page-layout jane-page-bg flex-grid-column p-flex-column color-palette-white-tx">
          <FlexGridItem className="jane-half-page">
            <ImageFilled url="images/anna/pexels-anna-kollor-14330820.png"  alt="" className="shift-left-50"/>
          </FlexGridItem>
          <FlexGridItem className="jane-half-page flex-center  ">
            <FlexGridItem className="flex-grid-row jane-text-section">
            <H2 className="text-handwritten">
              About me
            </H2>
            <TextNormal className="text-kalam"> 
              Hey there, I'm Jane Smith - an enthusiast for life's grand tapestry. I've spent my years embracing the dualities that make existence so fascinating: from embracing the solitude of serene hikes to 
              immersing myself in the vibrant energy of artistic expression. 
              Nature is where I find solace; the rustling leaves and distant 
              horizons are my constant companions. 
              When I'm not chasing sunsets, I'm channeling my 
              inner artist, capturing fleeting moments with brush 
              strokes and camera clicks. Join me as I navigate the 
              labyrinth of human emotions through creativity and 
              forge connections that remind us of our shared humanity. 
              Welcome to my world of boundless curiosity and endless 
              exploration.
            </TextNormal>
            </FlexGridItem>
          </FlexGridItem>
      </FlexGridItem>
      <FullPageLayout className="jane-page-bg">
        {/* Grid For Image */}
        <ImageGridSquare images={imageList} />
      </FullPageLayout>
      <FullPageLayout className="color-palette-white-tx width-90" >
        Disclaimer: This portfolio website is a fictional one and doesn't consitute for any real person. Bio content generated using LLM Models. Images by Anna Koller from <a href="https://www.pexels.com/@anna-kollor-275578354/" className='no-link-decor'>Pexels.com</a>
      </FullPageLayout>
    </>
  )
}